import { Title } from "@solidjs/meta";
import { MetaTags } from "~/components/shared/MetaTags";
import Enga2Page from "~/components/Enga/Enga2Page";

export default function () {
  const metaDesc = {
    tag: "meta",
    attributes: {
      name: "description",
      content:
        "Construire des biens qui font du bien, c’est construire des bâtiments plus économes en énergie, plus respectueux de leur environnement et de la biodiversité, et en choisissant méticuleusement des matériaux durables.",
    },
  };

  return (
    <>
      <Title>Engagement Cogedim - Le confort d’été</Title>
      <MetaTags tags={[metaDesc]} />
      <Enga2Page />
    </>
  );
}
